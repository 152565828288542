import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { getCookie } from 'typescript-cookie';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: {
      name: 'claim-overview',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */'@/views/login/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */'@/views/register/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */'@/views/forgot-password/index.vue'),
    meta: {
      layout: 'LoginLayout',
    },
  },
  {
    path: '/claim-overview',
    name: 'claim-overview',
    component: () => import(/* webpackChunkName: "claim-overview" */'@/views/dashboard/claim-overview/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/claim-overview/:productName',
    name: 'claim_productName',
    component: () => import(/* webpackChunkName: "claim_productName" */'@/views/dashboard/claim-overview/_productName/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/claim-overview/:productName/more-details',
    name: 'claim_productName-more-details',
    component: () => import(/* webpackChunkName: "claim_productName-more-details" */'@/views/dashboard/claim-overview/_productName/more-details/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/claim-overview/:productName/adjust-sizes',
    name: 'claim_productName-adjust-sizes',
    component: () => import(/* webpackChunkName: "claim_productName-adjust-sizes" */'@/views/dashboard/claim-overview/_productName/adjust-sizes/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const accessToken = getCookie('user_grant_access');
  const nextRoute = to.name?.toString() || '';
  if (['login', 'forgot-password'].includes(nextRoute)) {
    if (accessToken) {
      next({ name: 'claim-overview' });
    } else {
      next();
    }
  } else if (nextRoute === 'register') {
    next();
  } else {
    if (accessToken) {
      next();
    } else {
      next({ name: 'login' });
    }
  }
});

export default router;